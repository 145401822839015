import * as React from "react"
import { Layout } from "../../components/layout"
import {
    container,
    intro,
    callToAction,
} from "./shipping.module.css"

function Policies() {
    return (
        <div className={container}>
            <h1 className={intro}>Shipping Policy</h1>

            <p className={callToAction}>
                All items will be shipped within 1-3 business days. All items shipped will have a tracking number assigned once shipment is completed. We are not held liable for any shipping delays since it is out of our hands upon delivery to the USPS.
                <br />
                <br />Customer will be responsible for payment on shipped merchandise. We also offer local pickup for no charge. At checkout, this is where you are able to choose your delivery method.
                <br />
                <br />
            </p>
        </div>
    )
}

export default function PoliciesPage({ data }) {
    return (
        <Layout>
            <Policies />
        </Layout>
    )
}
